<template>
  <div>
    <ba-dialog
      btn-color="success"
      btn-name="Add User"
      btnTooltip="Add a user"
      title="Add User"
      :show="dialog"
      v-on:show-dialog="dialog = true"
    >
      <v-container fluid grid-list-xl>
        <v-layout wrap align-center>
          <v-flex xs6 sm6 md6>
            <v-form ref="invite-form" v-model="valid">
              <ui-text-edit label="Invitee Name" v-model="name" :rules="[rules.required]"></ui-text-edit>
              <ui-text-edit label="Invitee Email" v-model="email" :rules="[rules.required, rules.email]"></ui-text-edit>
              <ui-select2
                :items="roles"
                :label="$tc('_role')"
                :rules="[rules.required, rules.role]"
                v-model="role"
                keyField="id"
                labelField="name"
                keyOnly
                searchable
              ></ui-select2>
            </v-form>
          </v-flex>
          <v-flex xs12 class="text-xs-right">
            <ui-button color="primary" :loading="loading" @click.native.stop="save" :disabled="!canSave">
              Save
            </ui-button>
          </v-flex>
        </v-layout>
      </v-container>
    </ba-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import UiButton from '../ui/UiButton.vue';
import UiSelect2 from '../ui/UiSelect2.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';

export default {
  props: ['user'],
  components: {
    UiTextEdit,
    UiButton,
    UiSelect2,
  },
  data() {
    return {
      dialog: false,
      name: '',
      email: '',
      role: '',
      valid: false,
      rules: {
        required: (v) => !!v || 'Field is required',
        email: (v) => !!v.includes('@') || 'Email must be valid', // v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        role: (v) => !!v || 'Role must be selected',
      },
      roles: [],
      isRolesLoading: 0,
    };
  },
  computed: {
    loading() {
      return this.isRolesLoading > 0;
    },
    canSave() {
      return this.name !== '' && this.email !== '' && this.email.includes('@') && this.role !== ''; // TODO fix logic
    },
  },
  watch: {},
  methods: {
    async save() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        invite: {
          userName: this.name,
          email: this.email,
          roleId: this.role,
        },
      };

      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $invite: InviteInput!) {
            inviteUser(orgId: $orgId, invite: $invite) {
              success
              errors
            }
          }
        `,
        // Parameters
        variables: vars,
      });
      if (resp.errors) {
        this.showErrorSnackbar('Problem inviting user: ' + resp.errors.join('<br />'));
      }

      if (resp.data && resp.data.inviteUser) {
        if (resp.data.inviteUser.success) {
          this.showSuccessSnackbar('User Invited!');
          this.name = '';
          this.email = '';
          this.role = '';
          this.dialog = false;
          this.$emit('complete');
        } else {
          this.showErrorSnackbar('Problem inviting user: ' + resp.data.inviteUser.errors.join('<br />'));
        }
      }
    },
  },
  apollo: {
    roles: {
      query: gql`
        query getRoles($orgId: ID!) {
          roles(orgId: $orgId) {
            id
            name
            description
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      update(data) {
        return data.roles;
      },
      loadingKey: 'isRolesLoading',
    },
  },
};
</script>
