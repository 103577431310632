














import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import Invitees from '../../components/org/Invitees.vue';
@Component({
  components: {
    Invitees,
  },
})
export default class InviteesClass extends BaseVue {
  public selectedTab = 0;
}
